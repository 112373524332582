/*
 * @Author: huangl
 * @Date: 2022-09-14 19:52:37
 * @LastEditors: huangl
 * @LastEditTime: 2022-09-15 22:55:46
 * @Description: file content
 * @FilePath: \money-datav\vue-dataV\src\api\cockpit.js
 */
import request from "../utils/request.js";


//经营主体单位
export const getShop = params => {
  return request({
    method: "get",
    url: "/bigData/getShop",
    params
  });
};

//经营主体单位分页
export const getShopList = (params, typeId) => {
  return request({
    method: "get",
    url: `/bigData/getShopList/${typeId}`,
    params
  });
};

//检测共享实验室
export const getReport = params => {
  return request({
    method: "get",
    url: "/bigData/getReport",
    params
  });
};

//供应商分色管理
export const getSupplyShop = params => {
  return request({
    method: "get",
    url: "/bigData/getSupplyShop",
    params
  });
};

// 食品溯源--分类
export const getCategory = params => {
  return request({
    method: "get",
    url: "/bigData/getCategory",
    params
  });
};
// 食品溯源-分类商品列表
export const getReportByCategory = (categoryId) => {
  return request({
    method: "get",
    url: `/bigData/getReportByCategory/${categoryId}`,
  });
};

// 消息推送
export const getSystemMsg = (params) => {
  return request({
    method: "get",
    url: `/bigData/getSystemMsg`,
    params
  });
};
// 信用评价
export const getEvaluate = (params) => {
  return request({
    method: "get",
    url: `/bigData/getEvaluate`,
    params
  });
};

// 食链-票据管理
export const getBill = (params) => {
  return request({
    method: "get",
    url: `/bigData/getBill`,
    params
  });
};

// 冷库列表
export const getColdStorage = (params) => {
  return request({
    method: "get",
    url: `/bigData/getColdStorage`,
    params
  });
};

//车辆列表
export const getColdVehicle = (params) => {
  return request({
    method: "get",
    url: `/bigData/getColdVehicle`,
    params
  });
};

// 健康证列表
export const getStaff = (params) => {
  return request({
    method: "get",
    url: `/bigData/getStaff`,
    params
  });
};

// 测温记录
export const getStaffTemperature = (params) => {
  return request({
    method: "get",
    url: `/bigData/getStaffTemperature`,
    params
  });
};

export const getSupplyShopList = (params, typeId) => {
  return request({
    method: "get",
    url: `/bigData/getSupplyShopList/${typeId}`,
    params
  });
};

export const getEventList = (params) => {
  return request({
    method: "get",
    url: `/bigData/getEventList`,
    params
  });
};

//经营主体单位详情
export const getBigDataShop = (id) => request.get(`/v1/bigData/shop/${id}`);
//获取供应商管理
export const getShopGetShopList = (id, typeId) => request.get(`/v1/bigData/shop/getShopList/${id}/${typeId}`);
//获取从页人员
export const getShopStaff = (id) => request.get(`/v1/bigData/shop/getStaff/${id}`);
//车辆列表
export const getShopColdVehicle = (id) => request.get(`/v1/bigData/shop/getColdVehicle/${id}`);

//检测管理
export const getShopReport = (id) => request.get(`/v1/bigData/shop/getReport/${id}`);

//AI抓拍记录
export const getShopEventList = (id) => request.get(`/v1/bigData/shop/eventList/${id}`);

export const getShopAiCamera = (id, params) => request.get(`/v1/bigData/shop/getAiCamera/${id}`, {params});

//冷库管理
export const getShopColdStorage = (id, params) => request.get(`/v1/bigData/shop/getColdStorage/${id}`,  {params});

//获取车辆的定位
export const getShopTrack = (id) => request.get(`/v1/bigData/shop/getTrack/${id}`);
